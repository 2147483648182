import { useContext, Fragment, useEffect } from 'react';
import findIndex from 'lodash/findIndex';
import appContext from '../Context/appContext';
import CustomSlider from './CustomSlider';
import isMobile from '../helpers/isMobile';

const SelectedFiltersList = ({ filtersList, filtersType, className, setListLoading = () => { }, setBatch = () => { } }) => {
  const { dispatch } = useContext(appContext);

  const applyFilters = filterList => {
    dispatch({ type: 'setFilters', payload: { [filtersType]: filterList } });
  };

  const onChange = event => {
    setListLoading(true);
    let filterList = filtersList;
    const id = event.target.id;
    filterList = filterList.map((item, index, array) => {
      switch (item.type) {
        case 'sort_key':
          let initialSelected = item.values[0].checked
          if (id == 'asc' || id == 'desc' || id == 'none') {
            item.values = item.values.map(el => {
              el.checked = false;
              el.intended = false;
              return el;
            });
            if (initialSelected) {
              setListLoading(false)
            }
            array[0].values[0].checked = true;
          }
          break;
        default:
          item.values = item.values.map(el => {
            el.checked = el.id == id ? !el.checked : el.checked;
            return el;
          });
          break;
      }
      return { ...item };
    });

    setBatch(1)
    applyFilters(filterList);
    scrollToFilter();
    setTimeout(() => setListLoading(false), 1000);
  };


  const scrollToFilter = () => {
    const header = document.getElementsByClassName('bento-header')[0];
    let element = null;
    let headerOffset = 0;

    if (isMobile()) {
      // Scroll for mobile view
      const deliveryTabs = document.getElementById("delivery-tabs-box")

      if (deliveryTabs?.getBoundingClientRect().top - 55 - 20 > header?.clientHeight) { // 55px is taken by category section, 20px is buffer
        if (deliveryTabs?.getBoundingClientRect().top - 55 - 20 - 37 > header?.clientHeight) return //39px is the label header height
      }

      element = document.getElementsByClassName('filter-product-page')[0];
      headerOffset = 200;

    } else {
      // Scroll for desktop view
      let header = document.getElementsByClassName('bento-header')[0]
      let filter = document.getElementsByClassName("desktop-filter-container")[0]
      if (filter?.getBoundingClientRect().top > header?.clientHeight) {
        if (filter?.getBoundingClientRect().top - 37 - 20 > header?.clientHeight) return // 37px is advert header height & 20px is buffer
      };

      element = document.getElementById('list-top');
      headerOffset = (header?.clientHeight + filter?.clientHeight) || 150;
    }

    const elementPosition = element?.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
    });
  };


  const onPriceRangeRemove = () => {
    alert()
    const filterList = filtersList;
    const valuesIndexPriceRangeType = findIndex(filterList, { name: 'priceRange' });
    if (filterList[valuesIndexPriceRangeType]) {
      filterList[valuesIndexPriceRangeType].values[0].value = filterList[valuesIndexPriceRangeType].minPrice;
      filterList[valuesIndexPriceRangeType].values[1].value = filterList[valuesIndexPriceRangeType].maxPrice;
      filterList[valuesIndexPriceRangeType].values[0].text = null;
      filterList[valuesIndexPriceRangeType].isSet = false;
    }

    applyFilters(filterList);
  };

  const renderItems =
    filtersList &&
    filtersList?.map(filter => {
      const { name = "", values = [] } = filter;
      const filterType = getFilterType(name);


      const selectedFilters =
        name === "product_price"
          ? values.filter((item, index) => index === 0 ? (item.checked && item?.intended) : item.checked)
          : values.filter(item => item.checked)
      if (selectedFilters.length === 0) return;

      return (
        <div className={`${filterType} afl-container `}>
          {selectedFilters
            ?.map(item => {
              return (
                <div className={`chip ${item.name}-chips`}>
                  {item.name == 'min_price' ? (
                    <>
                      {item.text}
                      <button className="btn btn-sm s-close-btn">
                        <i className="icon icon-close" id={item.id} onClick={onPriceRangeRemove} />
                      </button>
                    </>
                  ) : (
                    <>
                      {item.value}
                      <button className="btn btn-sm s-close-btn">
                        <i className="icon icon-close" id={item.id} onClick={onChange} />
                      </button>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      );
    });

  // const renderItems =
  // filtersList &&
  // filtersList
  //   .map(filter => filter.values)
  //   .flat()
  //   .filter(item => item.checked && item.value !== filtersList[0].values[0].value)
  //   .map(item => {
  //     return (
  //       <div className={`chip ${item.name}-chips`}>
  //         {item.name == 'min_price' ? (
  //           <>
  //             {item.text}
  //             <button className="btn btn-sm s-close-btn">
  //               <i className="icon icon-close" id={item.id} onClick={onPriceRangeRemove} />
  //             </button>
  //           </>
  //         ) : (
  //           <>
  //             {item.value}
  //             <button className="btn btn-sm s-close-btn">
  //               <i className="icon icon-close" id={item.id} onClick={onChange} />
  //             </button>
  //           </>
  //         )}
  //       </div>
  //     );
  //   });

  return (
    <div className="container p-0" id="selected-filters-box">
      <Fragment>{filtersList && <CustomSlider className={` selected-filters ${className} `}>{renderItems}</CustomSlider>}</Fragment>
    </div>
  );
};

const getFilterType = (name = '') => {
  switch (name.toLowerCase()) {
    case 'product_price':
      return 'type-sort';

    case 'strains':
      return 'type-strain';

    case 'id':
      return 'type-category';

    case 'brands':
      return 'type-brand';

    case 'devicetypes':
      return 'type-device';

    default:
      return `type-${name}`;
  }
};

export default SelectedFiltersList;
